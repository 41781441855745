import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HOST_API_KEY } from "../../utils/Config-globals";

const initialState = {
  isLoading: false,
  error: null,
  user: null,
  userDetails: null,
};

// Async thunk for Register
export const Register = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${HOST_API_KEY}/auth/register/`, data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for SignUpWithGoogle
export const SignUpWithGoogle = createAsyncThunk(
  "auth/googleSignup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${HOST_API_KEY}/socialauth/google/`,
        data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for login
export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${HOST_API_KEY}/auth/login/`,
        credentials
      );
      localStorage.setItem("email", response.data.data.email);
      localStorage.setItem("access_token", response.data.data.access_token);
      localStorage.setItem("user_id", response.data.data.id);
      localStorage.setItem("user_name", response.data.data.full_name);
      localStorage.setItem("refresh_token", response.data.data.refresh_token);
      return { data: response.data, status: response.status };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for RecoverPassword
export const RecoverPassword = createAsyncThunk(
  "auth/recoverPassword",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${HOST_API_KEY}/auth/password-reset/`,
        credentials
      );
      console.log("response RecoverPassword", response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for ResetPassword
export const ResetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${HOST_API_KEY}/auth/reset-password/`,
        credentials
      );
      localStorage.setItem("access_token", response.data.access_token);
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for VerifyEmail
export const VerifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (credentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${HOST_API_KEY}/auth/verify-email/`,
        credentials
      );
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOtp = createAsyncThunk(
  "auth/getOtp",
  async (credentials, { rejectWithValue }) => {
    try {
      await axios.post(`${HOST_API_KEY}/auth/get-otp/`, credentials);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getResendOtp = createAsyncThunk(
  "auth/resend-otp",
  async (credentials, { rejectWithValue }) => {
    try {
      await axios.post(`${HOST_API_KEY}/auth/resend-otp/`, credentials);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/change-password",
  async (credentials, { rejectWithValue }) => {
    try {
      await axios.patch(`${HOST_API_KEY}/auth/change-password/`, credentials);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const UpdateUser = createAsyncThunk(
  "user/updateUser",
  async (userData, { rejectWithValue }) => {
    const userId = localStorage.getItem("user_id"); // Fetching the userId from local storage
    console.log(userId,"userId");
    try {
      const response = await axios.patch(
        `https://api-meetingroom.talksandtalks.ai/api/v1/auth/users/${userId}/edit/`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }
    const response = await axios.post(`${HOST_API_KEY}/auth/token/refresh/`, {
      refresh: refreshToken,
    });
    const newAccessToken = response.data.access;
    localStorage.setItem("access_token", newAccessToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
    return newAccessToken;
  } catch (error) {
    console.log("Error refreshing token", error);
    return null;
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoading = false;
      state.error = null;
      state.user = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data.data;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(Register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(Register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(VerifyEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(VerifyEmail.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(VerifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getResendOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResendOtp.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getResendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(RecoverPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(RecoverPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(RecoverPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(ResetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ResetPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(ResetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(SignUpWithGoogle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SignUpWithGoogle.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(SignUpWithGoogle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOtp.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
