import PropTypes from "prop-types";
import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoginPage from "../auth/LoginPage";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const token = localStorage.getItem("access_token"); // Fetch token from localStorage
  const [requestedLocation, setRequestedLocation] = useState(null);

  // If no token, redirect to login page
  if (!token) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <LoginPage />;
  }

  // If the user requested a location before logging in, redirect after login
  if (requestedLocation && pathname !== requestedLocation) {
    const redirectPath = requestedLocation;
    setRequestedLocation(null); // Clear the requested location
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>; // Render the children components if authenticated
}
