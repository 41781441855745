

export const path = (root, subpath, params = {}) => {
  let fullPath = `${root}${subpath}`;
  for (const [key, value] of Object.entries(params)) {
    fullPath = fullPath.replace(`:${key}`, value);
  }
  return fullPath;
};
const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";


// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  signUp: path(ROOTS_AUTH, "/sign-up"),
  emailCheck: path(ROOTS_AUTH, "/email-sucess"),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  emailVerification: path(ROOTS_AUTH, "/otp-verification"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
  createMeeting: (id) => path(ROOTS_AUTH, `/create-meeting/${id}`),
  joinCall: (meetingId) => path(ROOTS_AUTH, `/join-call/${meetingId}`),
  // emailSuccess: path
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  Overview: path(ROOTS_DASHBOARD, "/Overview"),

  Schedule: path(ROOTS_DASHBOARD, "/Schedule"),
  messenger: path(ROOTS_DASHBOARD, "/messenger"),
  JoinMeeting: (meetingId) => path(ROOTS_DASHBOARD, `/call/join/${meetingId}`),
  meeting: path(ROOTS_DASHBOARD, "/call/meet"),
};
