import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../utils/Axios";

const initialState = {
  isLoading: false,
  error: null,
  user: {},
  users: [],
};

// Async thunk for Get User
export const GetUser = createAsyncThunk(
  "auth/user",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await API.get(`/auth/users/${userId}/`);
      console.log(" get User response ", response);
      return response;
    } catch (error) {
      console.log("response", error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for Update User
export const SignUpWithGoogle = createAsyncThunk(
  "auth/googleSignup",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await API.get(`/auth/users/${userId}/`);

      console.log("response", response);
      return response;
    } catch (error) {
      console.log("response", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const UpdateUser = createAsyncThunk(
  "auth/user/edit",
  async (values, { rejectWithValue }) => {
    try {
      const response = await API.put(
        `/auth/users/${values.userId}/edit/`,
        values
      );

      console.log("update User response ", response);
      return response;
    } catch (error) {
      console.log("response", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  //   reducers: {
  //     logout: (state) => {
  //       state.isLoading = false;
  //       state.error = null;
  //       state.user = {};
  //       localStorage.removeItem("access_token");
  //     },
  //   },
  extraReducers: (builder) => {
    builder
      .addCase(GetUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(GetUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data.data;
      })
      .addCase(GetUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    // .addCase(UpdateUser.pending ,(state) => {
    //   state.isLoading = true;
    // })
  },
});

// export const { logout } = authSlice.actions;

export default userSlice.reducer;
