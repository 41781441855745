import PropTypes from "prop-types";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden"; // Import Hidden component
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import ForumIcon from "@mui/icons-material/Forum";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NotificationsIcon from "@mui/icons-material/Notifications";
import GroupIcon from "@mui/icons-material/Group";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import { PRIMARY, WHITE } from "../theme/Palette";

import logoImage from "../assets/icons/logos/videologo.png"; //for video call logo
//import logoImage from "../assets/icons/logos/logo.svg";//talksandtalks logo
import LanguageIcon from "@mui/icons-material/Language";
import Avatar from "@mui/material/Avatar";
 import profile from "../assets/images/profile/lady1.png";
import AccountPopover from "./AccpuntPopover";
import { Button, TextField } from "@mui/material";
import StarIcon from "../assets/icons/Nav/hourRemainIcon.svg";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer } from "../redux/slices/DrawerSlice";
import {
  navHeader,
  openSideBar,
  closeSideBar,
} from "../redux/slices/ThemeSlice";
import { CustomTypography4 } from "../components/Typography/Typography";
import CalenderIcon from "../assets/icons/logos/calendar_add_on.svg";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: theme.palette.background.default,
  transition: theme.transitions.create("width", {
   easing: theme.transitions.easing.sharp,
   duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
 justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 99,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  boxShadow: "none",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  background: "red",
  borderRight: "1px solid rgba(255, 255, 255, 0.15)",
  opacity: 1,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    background: "red",
    borderRight: "1px solid rgba(255, 255, 255, 0.15)",
    opacity: 1,
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      borderRight: "1px solid rgba(255, 255, 255, 0.15)",
      opacity: 1,
      background: theme.palette.background.default, // Also set the background color for the paper component
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    borderRight: "1px solid rgba(255, 255, 255, 0.15)",
    opacity: 1,
    background: theme.palette.background.default, // Apply the color when the drawer is closed
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      borderRight: "1px solid rgba(255, 255, 255, 0.15)",
      opacity: 1,
      background: theme.palette.background.default, // Also set the background color for the paper component
    },
  }),
}));

const HeadingText = styled(Typography)(() => ({
  fontFamily: "Yu Gothic UI",
  fontSize: "27.239px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "54.478px",
  color: "#fff",
}));

const LogoIconButton = styled(IconButton)({
  "& img": {
    width: "auto",
    height: "100%",
  },
});

export default function DashboardLayout({ children }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBar = useSelector((state) => state.theme.sideBar);
  const nav = useSelector((state) => state.theme.header);
  const [open, setOpen] = useState(sideBar);
  const [header, setHeader] = useState(nav);
  const [search, setSearch] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(openSideBar());
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(closeSideBar());
  };

  const headingLogos = {
    Overview: HomeIcon,
    Call: LocalPhoneIcon,
    Notification: NotificationsIcon,
    Messenger: ForumIcon,
    Schedule: CalendarMonthIcon,
    Contacts: GroupIcon,
    Documents: FileCopyIcon,
    Settings: SettingsIcon,
    Support: LanguageIcon,
  };
  const handleHeader = (text) => {
    dispatch(navHeader(text));
    setHeader(text);
    navigate(`/dashboard/${text}`);
    console.log(header);
  };

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleOpenDrawer = () => {
    dispatch(openDrawer());
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          open={open}
          sx={{
            background: theme.palette.background.default,
            borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
            opacity: 1,
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                // onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 2,
                  marginLeft: open ? 0 : "60px",
                  // ...(!open && { display: "none" }),
                  p: 1,
                  height: "40px",
                  opacity: 0.4,
                  borderRadius: "10px",
                  border: "1px solid rgba(255, 255, 255, 0.50)",
                  // display: "inline-block",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "rgba(255, 255, 255, 0.50)",
                  fontSize: "10px",
                }}
              >
                <MenuIcon />
              </IconButton>
              <CustomTypography4
                sx={{ marginLeft: open ? 0 : "5px", fontSize: "18px" }}
              >
                {nav}
              </CustomTypography4>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
              }}
            >
              {nav === "Schedule" && (
                <Button 
  variant="contained"
  startIcon={
    <img 
      src={CalenderIcon} 
      style={{ marginLeft: "1px" }}  // Adjusted the gap for icon and text
    />
  }
  onClick={handleOpenDrawer}
  sx={{
    textTransform: "none",
    width: {
      xs: "80px", // For small screens like mobile
      sm: "90px", // For small tablets
      md: "99px", // For medium screens and above
    },
    height: {
      xs: "36px", // Adjust height for small screens
      sm: "38px", // Slightly bigger on small tablets
      md: "40px", // Default height for medium and large screens
    },
    borderRadius: "8px",
    background: theme.palette.primary.info,
    color: theme.palette.primary.white,
    ":hover": {
      background: theme.palette.primary.info,
      color: theme.palette.primary.white,
    },
    fontSize: {
      xs: "10px", // Smaller text size for small screens
      sm: "11px", 
      md: "12px", // Larger text size for medium and larger screens
    },
  }}
>
  Schedule
</Button>
       )}
            
             {!search && 
          <IconButton
          sx={{
            border: `1px solid ${WHITE[50]}`,
            borderRadius: "8px",
            color: "rgba(255, 255, 255, 0.5)",
            height: {
              xs: "36px",  // Height for extra-small screens (mobile)
              sm: "38px",  // Height for small tablets
              md: "40px",  // Default height for medium screens and above
            },
            width: {
              xs: "36px",  // Width for extra-small screens (mobile)
              sm: "38px",  // Width for small tablets
              md: "40px",  // Default width for medium screens and above
            },
            fontSize: {
              xs: "18px",  // Adjust icon size for mobile screens
              sm: "20px",  // Icon size for small tablets
              md: "24px",  // Default icon size for medium and large screens
            },
          }}
          onClick={() => setSearch(true)}
        >
          <SearchIcon />
        </IconButton>
        
              }
              {search && (
             <Box 
             sx={{
               p: {
                 xs: 1,  // Smaller padding for extra-small screens
                 sm: 2,  // Default padding for small and larger screens
               }
             }}
           >
             <TextField
               fullWidth
               variant="outlined"
               placeholder="Search..."
               InputProps={{
                 style: {
                   height: {
                     xs: "36px",  // Smaller height for extra-small screens (mobile)
                     sm: "38px",  // Slightly larger height for small tablets
                     md: "40px",  // Default height for medium screens and larger
                   },
                   fontSize: {
                     xs: "12px",  // Smaller font size for extra-small screens
                     sm: "14px",  // Slightly larger font size for tablets
                     md: "16px",  // Default font size for medium screens and larger
                   },
                   padding: {
                     xs: "8px",   // Padding for extra-small screens
                     sm: "10px",  // Padding for small tablets
                     md: "12px",  // Padding for medium and larger screens
                   },
                   border: "rgba(255, 255, 255, 0.15)",  // Same border for all screens
                 },
               }}
             />
           </Box>
           
              )}


            <Button 
  variant="outlined"
  startIcon={
    <img
      src={StarIcon}
      alt="Hour Icon"
    />
  }
  sx={{
    color: "#fff",
    borderRadius: "8px",
    border: `1px solid ${WHITE[50]}`,
    width: {
      xs: "80px",  // Small width for extra-small screens like mobile
      sm: "90px",  // Slightly wider on small tablets
      md: "97px",  // Default width for medium and large screens
    },
    height: {
      xs: "36px",  // Shorter height for extra-small screens
      sm: "38px",  // Slightly taller for small tablets
      md: "40px",  // Default height for medium and large screens
    },
    textTransform: "none",
    fontWeight: 700,
    fontSize: {
      xs: "10px",  // Smaller font size for mobile screens
      sm: "11px",  // Slightly bigger for small tablets
      md: "12px",  // Default font size for medium and large screens
    },
    lineHeight: "18px",
    alignItems: "center",
    fontFamily: "Lato",
    whiteSpace: "nowrap", // Prevent text from breaking into multiple lines
  }}
>
  25 Hours
</Button>

              <Avatar sx={{borderRadius:"7px"}}
                variant="rounded"
                src={profile}
                
                onClick={handleOpenPopover}
              ></Avatar>
            </Box>
          </Toolbar>
        </AppBar>
        {/* <CallNavbar/> */}
        <AccountPopover
          openPopover={openPopover}
          setOpenPopover={setOpenPopover}
        />
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            borderRight: "1px solid rgba(255, 255, 255, 0.15)",
            opacity: 1,
            background: "var(--1-a-1-c-1-d-primary, #1A1C1D)",
          }}
        >
          <DrawerHeader sx={{ marginBottom: "4rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LogoIconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 4 }}
                onClick={handleDrawerOpen}
              >
                <img src={logoImage} alt="Logo" style={{height:"41.73px",width:"45.44px",marginLeft:"0rem"}} />
              </LogoIconButton>
              <Hidden smDown>
                {open && (
                  <HeadingText>
                    <Typography variant="h6">Talks & Talks</Typography>
                  </HeadingText>
                )}
              </Hidden>
            </Box>
            {open && (
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon sx={{ color: "#fff" }} />
                ) : (
                  <ChevronLeftIcon sx={{ color: "#fff" }} />
                )}
              </IconButton>
            )}
          </DrawerHeader>
          {/* <Divider /> */}
          <List sx={{ padding: "10px", overflowX: "hidden" }}>
            
            {[
              "Overview",
              "Call",
              "Notification",
              "Messenger",
              "Schedule",
              "Contacts",
              "Documents",
              "Settings",
              "Support",
            ].map((text, index) => {
              const IconComponent = headingLogos[text]; // Get the icon component dynamically
              return (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ display: "block", marginTop: "15px" }}
                >
                  <ListItemButton
                    sx={{
                      // minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 1.5,
                      // border: open ? "1px solid transparent" : "none",
                      borderRadius: "14px",
                      // "&:hover": {
                      //   border: "1px solid transparent",
                      // },
                      ...(text === nav && {
                        border: `1px solid ${theme.palette.primary.dark}`,
                        opacity: 1,
                      }),
                    }}
                    onClick={() => handleHeader(text)} // Update the selected index onClick
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {IconComponent && (
                        <IconComponent
                          sx={{
                            color: text === nav ? "#fff" : PRIMARY.dark,
                          }}
                        />
                      )}
                      {/* Render the icon if it exists */}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: text === nav ? "#fff" : PRIMARY.dark,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: "100vh",
            background: theme.palette.background.default,
          }}
        >
          <DrawerHeader />
          <Outlet />
          {children}
        </Box>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
