import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../utils/Axios";
import { refreshAccessToken } from "../../redux/slices/AuthSlice";

const initialState = {
  isLoading: false,
  error: null,
  meeting: {},
  meetings: [],
  meetingEndState: false,
  QuickMeeting: {},
  SpeechToTextData: null,
  TranslatedTextData: null,
  supportedLanguages: [],
  opponent:null
}; 

// Helper function to handle token refresh
const handleTokenRefresh = async (error, rejectWithValue, originalRequest) => {
  if (error.response?.status === 401) {
    try {
      const newToken = await refreshAccessToken();
      if (newToken) {
        // Retry the original request with the new token
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        const response = await API(originalRequest);
        return response;
      }
    } catch (refreshError) {
      return rejectWithValue(refreshError.response.data);
    }
  }
  return rejectWithValue(error.response.data);
};

export const fetchUserDetails = createAsyncThunk(
  "user/fetchDetails",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await API.get(`/auth/users/${userId}/`);
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "get",
        url: `/auth/users/${userId}/`,
      });
    }
  }
);

// Async thunk for Schedule Meeting
export const Schedule = createAsyncThunk(
  "call/schedule/",
  async (meetingDetails, { rejectWithValue }) => {
    try {
      const response = await API.post(`/dashboard/call/schedule/`, meetingDetails);
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/call/schedule/`,
        data: meetingDetails,
      });
    }
  }
);

export const GetUpcomingMeetings = createAsyncThunk(
  "call/upcoming/",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard/call/upcoming/`);
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "get",
        url: `/call/upcoming/`,
      });
    }
  }
);

//eslint-disable no-unused-vars
export const QuickMeeting = createAsyncThunk(
  "call/quick/",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/dashboard/call/quick/`, data);
      console.log(" quick response ", response);
      return response;
    } catch (error) {
      console.log("quick response", error);
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/call/quick/`,
        data,
      });
    }
  }
);

export const getOpponent = createAsyncThunk(
  'meeting/getOpponent',
  async ({ quickCallId }, {rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("access_token"); 
      if (!accessToken) {
        throw new Error("Access token is missing.");
      }

      const response = await API.get(`/dashboard/call/opponent-preferred-language/?quick_call_id=${quickCallId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}` // Set the access token in the headers
        }
      });
      
      return response.data;
    } catch (error) {
      console.error("Error fetching opponent languages:", error);
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const getSupportedLanguages = createAsyncThunk(
  'meeting/getSupportedLanguages',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get('/dashboard/call/get-supported-languages/');
      if (Array.isArray(response.data.languages)) {
        return response.data.languages;
      } else {
        throw new Error("Unexpected API response format");
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const SpeechToText = createAsyncThunk(
  "call/speech-to-text/",
  async ({ audioContent, meeting_id, user_id, guest_email }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/dashboard/call/speech-to-text/`, { 
        audioContent, 
        meeting_id, 
        user_id, 
        guest_email 
      });
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/dashboard/call/speech-to-text/`,
        data: { audioContent, meeting_id, user_id, guest_email },
      });
    }
  }
);

export const TranslateText = createAsyncThunk(
  "call/translate-text/",
  async ({ text, sourceLanguage, targetLanguage }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/dashboard/call/translate-text/`, {
        text,
        sourceLanguage,
        targetLanguage,
      });
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/dashboard/call/translate-text/`,
        data: { text, sourceLanguage, targetLanguage },
      });
    }
  }
);
export const TextToSpeech = createAsyncThunk(
  "call/text-to-speech/",
  async ({ text, language }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/dashboard/call/text-to-speech/`, {
        text,
        language,
      });
      return response.data; // Assuming the API returns the audio data or URL
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/dashboard/call/text-to-speech/`,
        data: { text, language },
      });
    }
  }
);


const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    addMeetingDetails: (state, action) => {
      state.meeting = action.payload;
    },
    meetingEnd: (state) => {
      state.meetingEndState = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Schedule.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(Schedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meeting = action.payload;
        state.meetings.push(action.payload); // Update meetings state with the new meeting
      })
      .addCase(Schedule.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(GetUpcomingMeetings.pending, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(GetUpcomingMeetings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meetings = action.payload;
      })
      .addCase(GetUpcomingMeetings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(QuickMeeting.pending, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(QuickMeeting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.QuickMeeting = action.payload.data;
      })
      .addCase(QuickMeeting.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getOpponent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getOpponent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.opponent = action.payload;
         })
      .addCase(getOpponent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getSupportedLanguages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSupportedLanguages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.supportedLanguages = action.payload;
         })
      .addCase(getSupportedLanguages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(SpeechToText.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(SpeechToText.fulfilled, (state, action) => {
        state.isLoading = false;
        state.SpeechToTextData = action.payload;
      })
      .addCase(SpeechToText.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(TranslateText.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(TranslateText.fulfilled, (state, action) => {
        state.isLoading = false;
        state.translatedText = action.payload; // Save the translated text in the state
      })
      .addCase(TranslateText.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(TextToSpeech.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(TextToSpeech.fulfilled, (state, action) => {
        state.isLoading = false;
        state.speechAudioData = action.payload; // Store the audio data in the state
      })
      .addCase(TextToSpeech.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { addMeetingDetails, meetingEnd } = meetingSlice.actions;

export default meetingSlice.reducer;
