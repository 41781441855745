import React, { useState } from "react"; //ustate 
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material"; //circularprogress for loader
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CustomTextField } from "../../components/TextField/TextField";
import { CustomButton } from "../../components/Butons/CustomButton";
import GoogleIcon from "@mui/icons-material/Google";
import {
  CustomSubtitle2,
  CustomTypography4,
} from "../../components/Typography/Typography";
import { styled } from "@mui/material/styles";
import { PRIMARY } from "../../theme/Palette";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/slices/AuthSlice";
import { BpRadio } from "../../components/Radio/CustomRadio";
import faceBookImg from "../../assets/icons/logos/Icon.svg";

const CustomDivider = styled(Divider)({
  "&.MuiDivider-root::before, &.MuiDivider-root::after": {
    width: "100%",
    borderTop: "1px solid var(--ffffff-50, rgba(255, 255, 255, 0.50));",
    content: "''",
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.info,
}));

export default function LoginForm() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading circle when user click sigin button

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({ //for email validation
      email: Yup.string().email("Invalid email").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setErrorMessage(""); // Reset error message before submission
      setLoading(true); // Start loading when passsword enterd correct

      if (!values.email || !values.password) {
        setErrorMessage("Email and password are required.");
        setLoading(false); // Stop loading if fields are empty
        return;
      }

      try {
        const res = await dispatch(loginUser(values));
        if (loginUser.fulfilled.match(res)) {
          const { status } = res.payload;

          if (status === 200) {
            navigate("/dashboard/overview");
            resetForm();
          }
        } else if (loginUser.rejected.match(res)) {
          const error = res?.payload?.detail
          setErrorMessage(error);

                // Set timeout to clear the error message after 10 seconds
          setTimeout(() => {
            setErrorMessage("");
          }, 10000);


        }
      } catch (error) {
        console.log("Login error:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");

             // Set timeout to clear the error message after 10 seconds
             setTimeout(() => {
              setErrorMessage("");
            }, 10000);
            
      } finally {
        setLoading(false); // Stop loading after submission
      }
    },
  });

  const { errors, touched, handleSubmit, handleChange } = formik;

  const handleNavigate = () => {
    navigate("/auth/forgot-password");
  };

  const handleFieldChange = (e) => {
    const { name } = e.target;

    // Clear the specific field's error message when the user starts typing
    if (errors[name]) {
      formik.setFieldTouched(name, false, false);
      formik.setFieldError(name, "");
    }

    // Clear the general error message on typing
    if (errorMessage) {
      setErrorMessage("");
    }

    handleChange(e);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Email Input Field */}
          <Grid item xs={12} md={12}>
            <InputLabel
              htmlFor="input-with-icon-textfield"
              sx={{
                color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                fontSize: "14px",
                fontFamily: "Lato",
                fontWeight: "400",
                lineHeight: "21px",
              }}
            >
              Email
            </InputLabel>
            <CustomTextField
              fullWidth
              id="input-with-icon-textfield"
              name="email"
              placeholder="Type your email address"
              autoComplete="off"
              onChange={handleFieldChange} // Use custom field change handler
              InputProps={{
                style: {
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "19.2px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <MailIcon
                      sx={{
                        color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                        fontSize: "18px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>

          {/* Password Input Field */}
          <Grid item xs={12} md={12}>
            <InputLabel
              htmlFor="input-with-icon-textfield"
              sx={{
                color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                fontSize: "14px",
                fontFamily: "Lato",
                fontWeight: "400",
                lineHeight: "21px",
              }}
            >
              Password
            </InputLabel>
            <CustomTextField
              fullWidth
              id="input-with-icon-textfield"
              type="password"
              name="password"
              autoComplete="off"
              placeholder="Start typing ..."
              onChange={handleFieldChange} // Use custom field change handler
              InputProps={{
                style: {
                  fontFamily: "Lato",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "19.2px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon
                      sx={{
                        color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                        fontSize: "18px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Grid>

          {/* Error Message */}
          {errorMessage && (
            <Grid item xs={12}>
              <Typography
                color="error"
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                {errorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                sx={{ fontWeight: 700, fontSize: "14px", lineHeight: "21px" }}
                value="RememberMe"
                control={<BpRadio />}
                label="Remember me"
              />
              <CustomSubtitle2 sx={{ cursor: "pointer" }} onClick={handleNavigate}>
                Recover Password
              </CustomSubtitle2>
            </Box>
          </Grid>

          {/* Sign In Button with Loading circlw when we click sigin */}
          <Grid item xs={12} md={12}>

          <StyledButton
  variant="contained"
  sx={{
    height: "50px",
    borderRadius: "10px",
    background: theme.palette.primary.info, // Keep the same background color even when loading
    color: theme.palette.primary.white,
    textTransform: "none",
    "&:hover": {
      background: "#007CD3", // Apply hover color only when not loading
      boxShadow: "none",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}
  fullWidth
  type="submit"
  //disabled={loading} // Disable button while loading//commented for keep the color same when  click button
>
  {loading ? (
    <CircularProgress
      size={20} //  for Adjust the size of the loader
      sx={{ color: "white" }} 
    />
  ) : (
    <CustomTypography4>Sign In</CustomTypography4>
  )}
</StyledButton>



          </Grid>

          {/* Divider */}
          <Grid item xs={12} md={12} my={3}>
            <Box sx={{ paddingInline: "120px" }}>
              <CustomDivider>
                <CustomSubtitle2 sx={{ color: "rgba(128, 131, 163, 1)" }}>
                  Or
                </CustomSubtitle2>
              </CustomDivider>
            </Box>
          </Grid>

          {/* Sign Up with Google */}
          <Grid item xs={12} md={12} mt={2}>
            <CustomButton
              variant="contained"
              startIcon={
                <GoogleIcon sx={{ margin: "auto", color: "rgba(128, 131, 163, 1)" }} />
              }
              sx={{
                height: "50px",
                justifyContent: "flex-start",
                display: "flex",
                color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                "&:hover": {
                  background: "#434647",
                  boxShadow: "none",
                },
              }}
              fullWidth
              type="button"
            >
              <Box
                sx={{
                  textAlign: "left",
                  flex: 1,
                  marginLeft: "10px",
                  borderLeft: `1px solid ${PRIMARY.dark}`,
                  paddingLeft: "15px",
                  color: theme.palette.primary.white,
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <CustomTypography4> Sign Up with Google</CustomTypography4>
              </Box>
            </CustomButton>
          </Grid>

          {/* Sign Up with Facebook */}
          <Grid item xs={12} md={12} mt={-1}>
            <CustomButton
              variant="contained"
              startIcon={<img src={faceBookImg} style={{ margin: "auto" }} />}
              sx={{
                height: "50px",
                justifyContent: "flex-start",
                display: "flex",
                color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                paddingRight: "-15px",
                "&:hover": {
                  background: "#434647",
                  boxShadow: "none",
                },
              }}
              fullWidth
              type="button"
            >
              <Box
                sx={{
                  textAlign: "left",
                  flex: 1,
                  borderLeft: `1px solid ${PRIMARY.dark}`,
                  paddingLeft: "15px",
                  color: theme.palette.primary.white,
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <CustomTypography4>Sign Up with Facebook</CustomTypography4>
              </Box>
            </CustomButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
